
import vClickOutside from "v-click-outside";
const { bind, unbind } = vClickOutside.directive;

export default {
  name: "RenderlessWithClickOutside",
  methods: {
    handleClick(e) {
      // console.log('Clicked outside. Event:1111 ', e)
    },
    handleOutsideClick(e) {
      // console.log('Clicked outside. Event: ', e)
      this.$emit('clickOutside')
    },
  },
  mounted() {
    this._el = document.querySelector("[data-rwco]");

    if (this._el) {
      bind(this._el, { value: this.handleOutsideClick });
    }
  },
  beforeDestroy() {
    if (this._el) {
      unbind(this._el);
    }
  },

  render(h) {
    return this.$scopedSlots.default({
      props: {
        // we can't pass vue a ref attrubute up, as in we can
        // but will not be a vue $ref.
        // That being said we'll always have the mightu DOM.
        "data-rwco": true,
        // we also can't pass v-click-outside-here :(
        // since it will be just an html attribute
      },
      listeners: {
        click: this.handleClick,
      },
    });
  },
};
