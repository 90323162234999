
import UrlImageHandler from "@/mixins/UrlImageHandler";
import ResizeHandler from '@/mixins/ResizeHandler'

export default {
  name: "SmallBannerItem",
  props: {
    showDescription: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object
    },
    maxHeight: {
      type: Number
    },
    width: {
      type: Number
    },
  },
  mixins: [UrlImageHandler, ResizeHandler],
  data() {
    return {
      imgUrl: ''
    }
  },
  methods: {
    redirect(item) {
      if (item.link_url) {
        window.location.href = item.link_url
      }
    }
  }
}
