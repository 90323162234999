
import SlickCarousel from "@/components/Elements/SlickCarousel";
import SmallBannerItem from "@/components/Banner/SmallBannerList/SmallBannerItem";
export default {
  name: "SmallBannerList",
  components: {SmallBannerItem, SlickCarousel},
  props: {
    showDescription: {
      type: Boolean,
      default: false
    },
    isPrimaryBanner: {
      type: Boolean,
      default: false
    },
    banners: {
      type: Array
    },
    maxHeight: {
      type: Number
    },
    width: {
      type: Number
    },
    slickOptions: {
      type: Object
    },
  },
}
