
import config from '@/utils/config'
import SlickCarousel from '@/components/Elements/SlickCarousel'
import UrlImageHandler from '@/mixins/UrlImageHandler'
import SmallBannerList from "@/components/Banner/SmallBannerList/SmallBannerList";
import {ACTION_GET_BANNER_CUSTOM} from "@/utils/store.action";
import NuxtSSRScreenSize from "nuxt-ssr-screen-size";
export default {
  name: "CarBanner",
  mixins: [UrlImageHandler, NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  components: {SmallBannerList, SlickCarousel },
  props: ['position'],
  data() {
    return {
      config,
      banners: [],
      carsRelated: [],
      responsiveOptions: [
        {
          breakpoint: '3840px',
          numVisible: 4,
          numScroll: 1
        },
        {
          breakpoint: '2560px',
          numVisible: 4,
          numScroll: 1
        },
        {
          breakpoint: '1920px',
          numVisible: 4,
          numScroll: 1
        },
        {
          breakpoint: '1536px',
          numVisible: 4,
          numScroll: 1
        },
        {
          breakpoint: '1280px',
          numVisible: 3,
          numScroll: 1
        },
        {
          breakpoint: '1024px',
          numVisible: 2,
          numScroll: 1
        },
        {
          breakpoint: '768px',
          numVisible: 1,
          numScroll: 1
        },
        {
          breakpoint: '480px',
          numVisible: 1,
          numScroll: 1
        }
      ],
      slickOptions: {
        arrows: false,
        dots: false,
        draggable: true,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        responsive: [
          {
            "breakpoint": 3856,
            "settings": {
              "slidesToShow": 4,
              "slidesToScroll": 1,
            }
          },
          {
            "breakpoint": 2560,
            "settings": {
              "slidesToShow": 4,
              "slidesToScroll": 1,
            }
          },
          {
            "breakpoint": 1920,
            "settings": {
              "slidesToShow": 4,
              "slidesToScroll": 1,
            }
          },
          {
            "breakpoint": 1280,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 1,
            }
          },
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 1,
            }
          },
          {
            "breakpoint": 768,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 1,
            }
          },
          {
            "breakpoint": 640,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
            }
          }
        ]
      }
    }
  },
  async fetch() {
    this.banners = await this.$store.dispatch(ACTION_GET_BANNER_CUSTOM, {screen: config.screenBanner.CAR_LIST, position: this.position || 1})
  }
}
