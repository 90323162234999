
import config from "../../utils/config";
import RenderlessWithClickOutside from "../Elements/RenderlessWithClickOutside.vue";
import NuxtSSRScreenSize from "nuxt-ssr-screen-size";
import RudderStackHandler from "../../mixins/RudderStackHandler";

export default {
  name: "SearchInputContainer",
  components: {RenderlessWithClickOutside},
  mixins: [RudderStackHandler],
  data() {
    return {
      showSug: false,
      isOpen: false,
      isFocusing: false,
      searchStr: '',
      listTrending: [],
    }
  },
  created() {
    this.getTrending()
  },
  methods: {
    hideSug() {
      if (this.showSug && this.isOpen && !this.isFocusing) {
        this.showSug = false
        this.isOpen = false
      }
    },
    focusOut() {
      this.isFocusing = false
    },
    async focusInput() {
      this.isFocusing = true
      if (!this.showSug) {
        this.showSug = true
        setTimeout(() => {
          this.isOpen = true
        }, 100)
      }
    },
    async getTrending() {
      try {
        const res = await this.$axios.$get(`${config.api.trending}`)
        if (res.length) {
          this.listTrending = res
        }
      } catch (e) {
      }
    },
    handleClick(text) {
      this.searchStr = text
      this.rudderTrack(config.newEventName.searched, {
        search_term: text,
      })
      this.hideSug()
      this.$router.push(`/mua-xe?q=${text.trimStart().trimEnd()}`)
    },
    onFocusSearch() {
      // if (!this.showSug) {
      //   this.showSug = true
      // }
    },
    onBlurSearch() {
      // this.showSug = false
    },
    searchFunc() {
      if (this.searchStr) {
        this.rudderTrack(config.newEventName.searched, {
          search_term: this.searchStr,
        })
        this.$router.push(`/mua-xe?q=${this.searchStr.trimStart().trimEnd()}`)
      } else {
        this.$nuxt.$emit('toggleSearchCarV2')
      }
    },
  }
}
