
import config from "@/utils/config";
import UrlImageHandler from "@/mixins/UrlImageHandler";
import RudderStackHandler from "@/mixins/RudderStackHandler";
import ResizeHandler from "@/mixins/ResizeHandler";
import NuxtSSRScreenSize from 'nuxt-ssr-screen-size'
import {ACTION_ADD_INFOR_SELLCAR, ACTION_GET_BRAND_APP, ACTION_GET_MANUFACTURES_APP} from "@/utils/store.action";
import card1 from '../../assets/imgs/home/card1.svg'
import card2 from '../../assets/imgs/home/card2.svg'
import card3 from '../../assets/imgs/home/card3.svg'
import card4 from '../../assets/imgs/home/card4.svg'
import mcard1 from '../../assets/imgs/home/mcard1.svg'
import mcard2 from '../../assets/imgs/home/mcard2.svg'
import mcard3 from '../../assets/imgs/home/mcard3.svg'
import mcard4 from '../../assets/imgs/home/mcard4.svg'
import SearchInputContainer from "../HomeNew/SearchInputContainer.vue";
import CarBanner from "../CarList/CarBanner.vue";
import {ACTION_GET_BANNER_CUSTOM} from "../../utils/store.action";
import SmallBannerList from "../Banner/SmallBannerList/SmallBannerList.vue";
import SmallBannerItem from "../Banner/SmallBannerList/SmallBannerItem.vue";

export default {
  name: 'FeatureCard',
  components: {SmallBannerItem, SmallBannerList, CarBanner, SearchInputContainer},
  mixins: [UrlImageHandler, RudderStackHandler, NuxtSSRScreenSize.NuxtSSRScreenSizeMixin, SearchInputContainer],
  props: ['manufacturers', 'figures'],
  data() {
    return {
      listCard: [card1, card2, card3, card4],
      listCardM: [mcard1, mcard2, mcard3, mcard4],
      provinces: config.provinces,
      timeTrack: {},
      searchStr: '',
      manufacturerApp: [],
      brandApp: [],
      keyCarouselStyle: 1,
      keyCarouselBrand: 1,
      loadingStyle: true,
      loadingBrand: true,
      loading: false,
      banners: [],
      slickOptions: {
        arrows: false,
        dots: false,
        draggable: true,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        responsive: [
          {
            "breakpoint": 3856,
            "settings": {
              "slidesToShow": 4,
              "slidesToScroll": 1,
            }
          },
          {
            "breakpoint": 2560,
            "settings": {
              "slidesToShow": 4,
              "slidesToScroll": 1,
            }
          },
          {
            "breakpoint": 1920,
            "settings": {
              "slidesToShow": 4,
              "slidesToScroll": 1,
            }
          },
          {
            "breakpoint": 1280,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 1,
            }
          },
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 1,
            }
          },
          {
            "breakpoint": 768,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 1,
            }
          },
          {
            "breakpoint": 640,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
            }
          }
        ]
      },
      listSug: [
        {
          label: ''
        }
      ],
      form: {
        name: '',
        phoneNumber: '',
        manufacturer: '',
        brand: ''
      },
      activeTab: 'mua-xe',
      showSug: false,
      display: false,
      messagesError: ''
    }
  },
  watch: {
    'form.manufacturer': {
      handler: async function (data, oldVal) {
        if (data.id) {
          if (data.id === 1512186 || data.id === 1512189) {
            this.form.manufacturer = oldVal
          } else {
            const brand = await this.$store.dispatch(ACTION_GET_BRAND_APP, {
              limit: 1000,
              manufacturer_id: this.form.manufacturer.id
            })
            if (brand && brand.length) {
              this.brandApp = brand
            }
          }
        }
      },
      deep: true,
      immediate: false
    },
  },
  async fetch() {
    this.banners = await this.$store.dispatch(ACTION_GET_BANNER_CUSTOM, {
      screen: config.screenBanner.HOME_PAGE,
      position: 2
    })
  },
  created() {
    this.getManufacturesAppresApp()
  },
  methods: {
    async getManufacturesAppresApp() {
      const res = await this.$store.dispatch(ACTION_GET_MANUFACTURES_APP, {limit: 1000})
      if (res && res.length) {
        const popular = []
        for (const item of res) {
          if (item.is_popular) {
            popular.push(item)
          }
        }
        if (popular.length) {
          this.manufacturerApp = [
            {
              id: 1512186,
              name: 'Thương hiệu nổi bật',
              disabled: true
            },
            ...popular,
            {
              id: 1512189,
              name: 'Tất cả thương hiệu',
              disabled: true
            },
            ...res]
        } else {
          this.manufacturerApp = [
            {
              id: 1512186,
              name: 'Tất cả thương hiệu',
              disabled: true
            },
            ...res]
        }
        if (this.$route.query.manufacturer) {
          const manufacturer = res.find(item => item.id === +this.$route.query.manufacturer) || {}
          if (manufacturer && manufacturer.id) {
            this.manufacturer = manufacturer
          }
        }
      }
    },
    blockSpecialCharSDT: function (e) {
      let k = e.keyCode
      if (k >= 48 && k <= 57) {
        return e.returnValue = true
      } else {
        return e.returnValue = false
      }
    },
    searchCarByPrice(from, to) {
      this.$router.push(`/mua-xe?price=${from},${to}`)
    },
    redirect(item) {
      if (item.link_url) {
        window.location.href = item.link_url
      }
    },
    changeTab(tab) {
      // this.activeTab = tab
      if (tab === 'ban-xe') {
        this.$router.push('/ban-xe')
      } else {
        this.$router.push('/mua-xe')
      }
    },
    onFocusSearch() {
      this.showSug = true
      this.timeTrack.timeIn = this.$moment().unix()
    },
    onBlurSearch() {
      // this.showSug = false
      this.timeTrack.timeOut = this.$moment().unix()
    },
    searchFunc() {
      this.timeTrack.timeOut = this.$moment().unix()
      if (this.searchStr) {
        this.$router.push(`/mua-xe?q=${this.searchStr.trimStart().trimEnd()}`)
      } else {
        // this.$router.push(`/mua-xe`)
        this.$nuxt.$emit('toggleSearchCarV2')
      }
      // this.$router.push(`/tim-xe`)
    },
    async onSubmit() {
      const {manufacturer, brand} = this.form || {}
      if (manufacturer && brand) {
        this.$router.push(`/ban-xe?manufacturer=${manufacturer.id || ''}&brand=${brand.id || ''}`)
      }

      // // version cũ
      // this.loading = true
      // this.form.phoneNumber = this.form.phoneNumber.replace(/\s/g, '')
      // const data = {
      //   ...this.form
      // }
      // // if (this.tracking_code.code) {
      // //   if ((Date.now() - this.tracking_code.createdAt) / (1000 * 60 * 60) < 24) {
      // //     data.tracking_code = this.tracking_code.code
      // //   }
      // // }
      // const res = await this.$store.dispatch(ACTION_ADD_INFOR_SELLCAR, data)
      // if (res._id) {
      //   this.loading = false
      //   await this.$router.push('/thankyou')
      // } else {
      //   this.loading = false
      //   this.messagesError = res.message
      //   this.display = true
      // }
    }
  },
}
